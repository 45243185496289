// 新版本 优化掉传统config

import {
  map, T, toLower, mergeDeepLeft, reduce, toPairs, append,
  flip, includes, mergeLeft, chain, always, path, split, equals,
  test, prop, applySpec, cond, identity, dropLast, isEmpty, propSatisfies,
  isNil, complement, either, pipe, is, ifElse, flatten, pluck,
  when, join, assoc
} from 'ramda'
import { flatMap } from 'lodash-es'
import decodeUriComponent from 'decode-uri-component';

import { revertSpecialCharacters } from 'helpers/utilities'
import { isMobile } from 'react-device-detect';

const no = propSatisfies(either(isEmpty, isNil))
const has = complement(no)

const keywordParser = pipe(toLower, cond([
  [test(/((\B|\b)-jobs-in-\b)/g), split('-jobs-in-')],
  [test(/((\B|\b)-jobs\b)/g), pipe(split('-jobs'), dropLast(1))],
  [equals('job-search'), always([])],
  [T, always([])]
]))

export const v2ConfigItems = applySpec({
  location: pipe(path(['location_filter']), chain(prop('locations'))),
  jobType: pipe(path(['job_type_filter'])),
  salary: pipe(config => config.salary_filter[0].salary_range_list?.concat(config.salary_filter[1].salary_range_list)?.concat(config.salary_filter[2].salary_range_list)?.concat(config.salary_filter[3].salary_range_list)),
  workExperience: pipe(path(['xp_lvl_filter'])),
  qualification: pipe(path(['degree_filter'])),
  mainFunctions: pipe(path(['main_function_filter'])),
  jobFunctions: pipe(
    path(['main_function_filter']),
    map(prop('sub_function_list')),
    flatten
  ),
})

export const v2ConfigKeys = pipe(v2ConfigItems, map(
  map(ifElse(has('seo-value'),
    prop('seo-value'),
    ifElse(has('function_title_value'),
      prop('function_title_value'),
      prop('seo_value'))))))

const v2KeywordMatches = pipe(
  v2ConfigKeys,
  toPairs,
  map(([key, list]) => [flip(includes)(list), applySpec({ [key]: Array.of })]),
  append([T, applySpec({ query: decodeUriComponent })]),
  cond
)

const v2ParseKeywordParams = config => pipe(
  keywordParser,
  map(v2KeywordMatches(config)),
  (words) => {
    if (words.length >= 1) {
      const [first, ...rest] = words
      if (first?.query) {
        first.query = revertSpecialCharacters(first.query)
      }
      words[0] = first
    }
    return words
  },
  reduce(mergeLeft, {})
)

export const v2Decoder = config => (path, params = {}) => mergeDeepLeft(
  v2ParseKeywordParams(config)(decodeUriComponent(path)),
  map(ifElse(is(String), split(','), identity))(params)
)

export const handleSalary = (salaryRanges = [], salaryList = []) => {
  const selected = salaryRanges.map(seo => salaryList.find(item => item['seo_value'] === seo))
  if (selected?.length > 0) {
    const from = selected?.map(item => item?.from).join(',')
    const to = selected?.map(item => item?.to).join(',')
    return [from, to]
  } else {
    return [null, null]
  }

}

// 将一个 MainJobFunction 扩展成 对应的main_functions、job_functions、function_titles
export const getJobFunctionAndJobTitles = pipe(
  pluck('sub_function_list'),     // 提取每个对象中的 sub_function_list
  flatten,                        // 将多个 sub_function_list 数组合并成一个数组
  subFunctions => ({
    subFunctions,                 // 返回所有 sub_function_list
    jobTitles: pipe(
      pluck('job_titles'),        // 提取每个 sub_function_list 中的 job_titles
      flatten                     // 将多个 job_titles 数组合并成一个数组
    )(subFunctions)
  })
);

// 通过 seo-value 获取 id 
export const v2BuildParams = (config, searchValues) => {
  if (!config || !searchValues) return
  const industryList = config.industry_filter
  const companySizeList = config.company_size_filter
  const qualificationList = config.degree_filter
  const financingStagesList = config.financing_stage_filter

  const mainFunctionList = config.main_function_filter
  const { subFunctions: jobFunctionList, jobTitles: functionsTitleList } = getJobFunctionAndJobTitles(config.main_function_filter)

  let salaryType = (config.salary_filter || []).find(v => {
    return v['seo_value'] === searchValues.salaryType?.join()
  })

  let salaryTypeId = salaryType?.salary_type_id
  salaryType = salaryTypeId === 2 ? 'daily' : salaryTypeId === 3 ? 'hourly' : salaryTypeId === 4 ? 'annually' : 'monthly';

  const initType = {
    "annually": {
      salaryList: config.salary_filter[0]?.salary_range_list,
      salaryKey: 'annually_salary'
    },
    "monthly": {
      salaryList: config.salary_filter[1]?.salary_range_list,
      salaryKey: 'salary'
    },
    "daily": {
      salaryList: config.salary_filter[2]?.salary_range_list,
      salaryKey: 'daily_salary'
    },
    "hourly": {
      salaryList: config.salary_filter[3]?.salary_range_list,
      salaryKey: 'hourly_salary'
    },

  }
  const { salaryList = config.salary_filter?.[1]?.salary_range_list } = initType[salaryType] || {}
  const [salaryFrom, salaryTo] = handleSalary(searchValues.salary, salaryList)
  const workExperienceList = config.xp_lvl_filter
  const jobTypeList = config.job_type_filter
  const locationLists = flatMap(config.location_filter, item => item.locations)
  const queryFields = searchValues?.queryFields?.join(',') || "job_title,company_name"
  const data = {
    query: searchValues.query,
    query_fields: queryFields === 'company' ? 'company_name' : queryFields,
    company_industry_ids: searchValues.industry?.map?.(key => industryList.find(item => item?.['seo_value'] === key)?.id).join(',') ?? undefined,
    job_location_ids: searchValues.location?.map?.(key => locationLists.find(item => item?.['seo_value'] === key)?.id).join(',') ?? undefined,
    job_type_ids: searchValues.jobType?.map?.(key => jobTypeList.find(item => item?.['seo_value'] === key)?.id).join(',') ?? undefined,
    xp_lvl_ids: searchValues.workExperience?.map?.(key => workExperienceList.find(item => item?.['seo_value'] === key)?.id).join(',') ?? undefined,
    degree_ids: searchValues.qualification?.map?.(key => qualificationList.find(item => item?.['seo_value'] === key)?.id).join(',') ?? undefined,
    company_financing_stage_ids: searchValues.financingStages?.map?.(key => financingStagesList.find(item => item?.['seo_value'] === key)?.id).join(',') ?? undefined,
    is_company_verified: searchValues.verifiedCompany ? Boolean(searchValues.verifiedCompany) : undefined,
    company_size_ids: searchValues.companySizes?.map?.(key => companySizeList.find(item => item?.['seo_value'] === key)?.id).join(',') ?? undefined,
    main_job_function_ids: searchValues?.mainFunctions?.map?.(seo => mainFunctionList.find(item => item.seo_value === seo)?.id)?.join?.(',') ?? undefined,
    job_function_ids: searchValues?.jobFunctions?.map?.(seo => jobFunctionList.find(item => item.seo_value === seo)?.id)?.join?.(',') ?? undefined,
    function_job_title_ids: searchValues?.functionTitles?.map?.(seo => functionsTitleList.find(item => item.seo_value === seo)?.id)
      ?.join?.(',') ?? undefined,
    page: searchValues?.page?.[0] ?? 1,
    size: 15,
    source: 'web',
    sort: searchValues?.sort?.join() || '2',
  }

  const { salaryKey } = initType[salaryType] || {}
  if (salaryKey) {
    data[`${salaryKey}_from`] = salaryFrom ?? undefined;
    data[`${salaryKey}_to`] = salaryTo ?? undefined;
  } else {
    console.error('salaryKey is empty, this is a bug we should fix')
  }

  return data
}

const source = isMobile ? "mobile" : "web";

const transformArraysToString = map(
  when(is(Array), when(isEmpty, () => null, join(',')))
);

const transformName = (obj) => {
  const keyMapping = {
    mainFunctions: "main_function",
    jobFunctions: "job_function",
    functionTitles: "function_job_title",
    salaryType: "salary_type",
    jobType: "job_type",
    qualification: "degree",
    industry: "company_industry",
    companySizes: "company_size",
    workExperience: "xp_lvl",
    financingStages: "company_financing_stage"
  };

  const newObj = {};

  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const newKey = keyMapping[key] || key;
      newObj[newKey] = obj[key];
    }
  }

  return newObj;
}

export const filterFunctionTitles = (function_job_title = [], main_function_filter = []) => {
  const toSeo = (value) => value?.replaceAll('/', '-')?.replaceAll(' ', '-')?.toLowerCase();
  const { subFunctions: jobFunctionList, jobTitles: functionsTitleList } = getJobFunctionAndJobTitles(main_function_filter)
  const newJobTitles = function_job_title.map(item => {
    const matchedChild = functionsTitleList.find(child => {
      let name = toSeo(child['seo_value']) + '-' + child.id;
      return name == item;
    });

    return matchedChild ? matchedChild['seo_value'] : null;
  }).filter(Boolean);

  return (newJobTitles ?? []).join(",")
}

export const transformParams = (input, lang) => pipe(
  transformName,
  transformArraysToString,
  assoc('page', input.page && input.page.length ? Number(input.page[0]) : 1),
  assoc('size', input.size && input.size.length ? Number(input.size[0]) : 15),
  assoc('sort', input.sort && input.sort.length ? Number(input.sort[0]) : 2),
  obj => ({ ...obj, source, lang }) // 添加 lang 和 source
)(input);


// 针对 RUL 添加参数 Mark path 的值是什么
// const firstKeyNameIn = keys => obj => find(key => obj[key] != null, keys);
// export const v2ConfigItems = applySpec({
//   location: pipe(path(['location_filter']), chain(prop('locations'))),
//   jobType: pipe(path(['job_type_filter'])),
//   salary: pipe(config => config.salary_filter[0].salary_range_list?.concat(config.salary_filter[1].salary_range_list)?.concat(config.salary_filter[2].salary_range_list)?.concat(config.salary_filter[3].salary_range_list)),
//   workExperience: pipe(path(['xp_lvl_filter'])),
//   // industry: pipe(path(['inputs', 'industry_lists'])),
//   qualification: pipe(path(['degree_filter'])),
//   mainFunctions: pipe(path(['main_function_filter'])),
//   jobFunctions: pipe(
//       path(['main_function_filter']),
//       map(prop('sub_function_list')),
//       flatten
//   ),
//   // functionTitles: pipe(path(['function_titles'])),
// })

// export const v2ConfigKeys = pipe(v2ConfigItems, map(
//   map(ifElse(has('seo-value'),
//       prop('seo-value'),
//       ifElse(has('function_title_value'),
//           prop('function_title_value'),
//           prop('seo_value'))))))

// const v2KeywordMatches = pipe(
//   v2ConfigKeys,
//   (params) => { console.log(params, "[["); return params },
//   // dissoc('functionTitles'),
//   toPairs,
//   map(([key, list]) => [flip(includes)(list), applySpec({ [key]: Array.of })]),
//   append([T, applySpec({ query: decodeUriComponent })]),
//   cond
// )

// const v2ParseKeywordParams = config => pipe(
//   keywordParser,
//   map(v2KeywordMatches(config)),
//   (words) => {
//       if (words.length >= 1) {
//           const [first, ...rest] = words
//           if (first?.query) {
//               first.query = revertSpecialCharacters(first.query)
//           }
//           words[0] = first
//       }
//       return words
//   },
//   reduce(mergeLeft, {})
// )

// export const v2Decoder = config => (path, params = {}) => mergeDeepLeft(
//   v2ParseKeywordParams(config)(decodeUriComponent(path)),
//   map(ifElse(is(String), split(','), identity))(params)
// )

// const newBuildQueryParams = cond([
//   // 都没有的情况下
//   [conditions.noParams, applySpec({
//       searchQuery: always('job-search'),
//       params: identity
//   })],
//   // 只有一个 job function 的情况下
//   [conditions.onlyOneFunctionTitle, params => newBuildQueryParams({
//       ...params,
//       functionTitles: [],
//       query: dropLast(1)(params.functionTitles[0].split('-')).join('-'),
//   })],
//   // 只选择了一个搜索条件
//   [conditions.onlyOne, params => {
//       const key = firstKeyIn(['query', 'location', ...userSelectKeys])(params);
//       const keyName = firstKeyNameIn(['query', 'location', ...userSelectKeys])(params);
//       return {
//           searchQuery: key + '-jobs',
//           params: {
//               // ...params,
//               page: either(prop('page'), always(1))(params),
//               sort: either(prop('sort'), always('1'))(params),
//               salaryType: either(prop('salaryType'), always('monthly'))(params),
//               pathValType: keyName
//           }
//       };
//   }],
//   // 有一个搜索条件和一个地址
//   [conditions.oneWithLocation, params => {
//       const key = firstKeyIn(['query', ...userSelectKeys])(params);
//       const keyName = firstKeyNameIn(['query', ...userSelectKeys])(params);
//       const location = prop('location')(params);
//       return {
//           searchQuery: `${key}-jobs-in-${location}`,
//           params: {
//               // ...params,
//               page: either(prop('page'), always(1))(params),
//               sort: either(prop('sort'), always('1'))(params),
//               salaryType: either(prop('salaryType'), always('monthly'))(params),
//               pathValType: keyName
//           }
//       };
//   }],
//   // 有多个查询条件, 且有query
//   [conditions.queryMany, params => {
//       const query = prop('query')(params);
//       return {
//           searchQuery: query + '-jobs',
//           params: {
//               ...dissoc('query', params),
//               pathValType: 'query'
//           }
//       };
//   }],
//   // 有多个查询条件, 没有query
//   [conditions.noQueryMany, applySpec({
//       searchQuery: always('job-search'),
//       params: identity
//   })]
// ]);

// export const newEncode = params => pipe(
//   filter(complement(either(isNil, isEmpty))),
//   map(
//       ifElse(is(Array), map(washData), washData)
//   ),
//   newBuildQueryParams,
//   result => {
//       const moreParams = normalParams(params)
//       return {
//           ...result, params: {
//               ...result.params, ...moreParams, size: 15,
//               source: isMobile ? "mobile" : 'web'
//           }
//       }
//   }
// )(params)

// export const newDecoder = (path, params = {}, lang = "en-us") => {
//   if (isEmpty(params)) {
// return {
//     page: 1,
//     size: 15,
//     source: isMobile ? "mobile" : "web",
//     sort: 2,
//     lang,
// }
//   }

//   const { pathValType = undefined, page = undefined, size = undefined, sort = undefined } = params
//   const defaultParams = {
//       page: page ? Number(page) : 1,
//       size: size ? Number(size) : 15,
//       source: isMobile ? "mobile" : "web",
//       sort: sort ? Number(sort) : 2,
//       lang,
//   };

//   if (!pathValType) {
//       return mergeRight(params, defaultParams);
//   }

//   const parsedPath = keywordParser(path);

//   const pathResult = cond([
//       [equals('job-search'), always({})],
//       [equals('query'), () => {
//           if (parsedPath.length === 2) {
//               return { query: revertSpecialCharacters(decodeUriComponent(parsedPath[0])), location: parsedPath[1] };
//           }
//           return { query: revertSpecialCharacters(decodeUriComponent(parsedPath.join('-'))) };
//       }],
//       [T, () => {
//           if (parsedPath.length === 2) {
//               return { [pathValType]: parsedPath[0], location: parsedPath[1] };
//           }
//           if (parsedPath.length === 1) {
//               return { [pathValType]: parsedPath[0] };
//           }
//           return {};
//       }]
//   ])(pathValType);

//   const updatedParams = mergeRight(params, defaultParams);
//   const processedParams = dissoc('pathValType', updatedParams);
//   return mergeDeepLeft(pathResult, processedParams);
// };