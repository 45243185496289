'use client'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter, usePathname } from 'next/navigation'
import type { MenuProps } from 'antd'
import { Menu } from 'antd'
import classNames from 'classnames'
import styled from '@emotion/styled'
import { useLanguage } from 'app/components/providers/languageProvider'

/* Redux */
import { logoutRequest } from 'store/actions/auth/logout'
import { toggleMenu } from 'store/actions/navigationBar/toggleMenu'
import { postNotification } from 'bossjob-remote/dist/clientStorage'

/* Components */
import Link from 'components/Link'
import Text from 'components/Text'

/* Helpers */
import { getCookie } from 'helpers/cookies'
import styles from './HamburgerMenu.module.scss'
import { getCountryKey, getLang, getLanguageId } from 'helpers/country'
import { languages } from 'helpers/country.base'

import linkToHunt from 'helpers/linkToHunt'
import { ChangeLangAndRegion } from 'components/Header/Common/ChangeLangAndRegion'
import { S3BucketJobseeker } from 'images'
import { useUserAgent } from 'next-useragent'

type MenuItem = Required<MenuProps>['items'][number]

const Divider = () => <div className={styles.divider} />

interface HamburgerMenuProps {
  openState?: boolean
  toggleMenu?: Function
  header: any
}
// {
//   lang.comm?.countryAndLanguage || 'Country and Language'
// }

const HamburgerMenu = ({ header }: HamburgerMenuProps) => {
  const {
    downloadApp,
    findJobs,
    companies,
    careerGuide,
    hiring,
    manageResume,
    myJobs,
    accountSettings,
    logOut,
    change,
    home,
    remoteJobs,
    recommendedJobs,
    onSiteJobs
  } = header || {}
  const pathname = usePathname()
  const router = useRouter()
  const dispatch = useDispatch()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [current, setCurrent] = useState('mail')
  const [openSwitchNationModal, setOpenSwitchNationModal] = useState<boolean>(false)
  const userCookie = getCookie('user')
  const [show, setShow] = useState(false)
  const userDetail = useSelector((store: any) => store.users.fetchUserOwnDetail?.response ?? {})
  const [showUnCompletedDot, setShowUnCompletedDot] = useState(false)
  const [countryKey, setCountryKey] = useState('ph')

  const langKey = getLang()
  const openState = useSelector((state: any) => state.navbar.toggleMenu.menu)
  const [clickAfter, setClickAfter] = useState(false)
  const userAgent = useUserAgent(globalThis?.navigator?.userAgent)
  const lang: any = useLanguage()
  // console.log('@lang:', lang)
  const languageId = getLanguageId()

  const languageSlug = languages.find((item) => item.id === languageId)?.slug
  const handleToggleMenu = () => {
    dispatch(toggleMenu())
  }

  useEffect(() => {
    if (userDetail?.id) {
      const hasJobPreferences = userDetail?.job_preferences.length > 0
      setShowUnCompletedDot(!userDetail?.is_profile_completed || !hasJobPreferences)
    }
    const countryKey = getCountryKey()
    setCountryKey(countryKey)
  }, [userDetail])

  // useEffect(() => {
  //   setOpenSwitchNationModal(false)
  // }, [openState])

  useEffect(() => {
    setIsAuthenticated(getCookie('sessionid') ? true : false)
  }, [userDetail])

  useEffect(() => {
    if (openState) {
      if (userAgent.isSafari) {
        document.body.style.position = 'fixed'
        document.body.style.top = '0px'
        document.body.style.width = '100%'
      }
      document.body.style.overflow = 'hidden'
    } else {
      if (userAgent.isSafari) {
        document.body.style.position = ''
        document.body.style.top = '0px'
        document.body.style.width = '100%'
      }
      document.body.style.overflow = 'auto'
    }
  }, [openState])

  const handleLogOut = () => {
    // enable body scroll again
    document.body.style.position = ''
    document.body.style.top = ''
    dispatch(logoutRequest())

    if (pathname === '/') {
      location.reload()
    } else {
      router.push('/')
    }
  }

  const handleClick = () => {
    // hide switch nation modal
    if (openSwitchNationModal) {
      setOpenSwitchNationModal(false)
      return
    }

    // enable body scroll again
    document.body.style.position = ''
    document.body.style.top = ''
    handleToggleMenu()
  }

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e)
    setCurrent(e.key)
  }

  const textStyle = 'base'

  // /(.*)\/my-jobs(.*)$/.test(pathname)

  const isOpenMenu =
    pathname.endsWith('/remote-jobs-hiring/jobs') ||
    pathname.endsWith('/my-jobs') ||
    pathname.endsWith('/jobs-hiring/job-search')

  return (
    <>
      <div
        className={classNames({
          [styles.maskshow]: openState,
          [styles.maskhide]: !openState && clickAfter
        })}
        onClick={() => {
          handleToggleMenu()
          setClickAfter(true)
        }}
      ></div>
      <div
        className={classNames({
          [styles.mobileFullPageMenu]: true,
          [styles.open]: openState,
          [styles.close]: !openState && clickAfter
        })}
      >
        {/* <div className={[styles.mobileFullPageMenu].join(' ') : styles.close}> */}
        <div className={styles.mobileFullPageMenuContainer}>
          <div
            style={{
              display: 'flex',
              height: 64,
              alignItems: 'center',
              // padding: '0 14px',
              justifyContent: 'flex-end'
            }}
          >
            {/* <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              onClick={() => {
                // postNotification('DISPATCH_CLOSE', toggleMenu())
                handleToggleMenu()
                setClickAfter(true)
              }}
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M3 4.875C3 4.39175 3.3134 4 3.7 4L16.3 4C16.6866 4 17 4.39175 17 4.875C17 5.35825 16.6866 5.75 16.3 5.75L3.7 5.75C3.3134 5.75 3 5.35825 3 4.875ZM3 10C3 9.51675 3.3134 9.125 3.7 9.125H16.3C16.6866 9.125 17 9.51675 17 10C17 10.4832 16.6866 10.875 16.3 10.875H3.7C3.3134 10.875 3 10.4832 3 10ZM3 15.125C3 14.6417 3.3134 14.25 3.7 14.25L16.3 14.25C16.6866 14.25 17 14.6418 17 15.125C17 15.6082 16.6866 16 16.3 16L3.7 16C3.3134 16 3 15.6082 3 15.125Z'
                fill='black'
              />
            </svg> */}
            <div
              className={classNames({
                [styles.closeIcon]: true,
                [styles.active]: openState
              })}
              onClick={() => {
                handleToggleMenu()
                setClickAfter(true)
              }}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <div className={styles.menuListWrapper}>
            <React.Fragment>
              <Link
                className={styles.defaultLink}
                to='/'
                title='Home'
                {...((pathname.endsWith('/') || new RegExp(`\/${langKey}$`).test(pathname)) && {
                  style: {
                    fontWeight: 500
                  }
                })}
              >
                <p
                  className={styles.menuList}
                  onClick={handleClick}
                  {...(pathname.endsWith('/') && {
                    'data-gtag-event': 'rl_active_home'
                  })}
                >
                  {home}
                </p>
              </Link>
              {/* <Link className={styles.defaultLink} to='/jobs-hiring/job-search' title='Jobs'>
              <li className={styles.menuList} onClick={handleClick}>
                <Text textStyle={textStyle}>{findJobs}</Text>
              </li>
            </Link> */}
              <div>
                {/* <li className={styles.menuList} > */}
                {/* <Text textStyle={textStyle}>{findJobs}----</Text> */}
                <MenuStyled
                  onClick={onClick}
                  mode='inline'
                  defaultOpenKeys={isOpenMenu ? ['sub1'] : null}
                  selectable={false}
                >
                  <Menu.SubMenu key='sub1' title={findJobs}>
                    <Menu.Item key='1'>
                      <Link
                        {...(pathname.endsWith('/remote-jobs-hiring/jobs') && {
                          style: {
                            fontWeight: 500
                          }
                        })}
                        className={styles.defaultLink}
                        to={'/' + langKey + '/remote-jobs-hiring/jobs'}
                        title='Remote Jobs'
                        onClick={handleClick}
                      >
                        <p
                          className={styles.menuList}
                          {...(pathname.endsWith('/remote-jobs-hiring/jobs') && {
                            'data-gtag-event': 'rl_active_find_jobs_remote'
                          })}
                        >
                          {remoteJobs}
                        </p>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key='2'>
                      <Link
                        className={styles.defaultLink}
                        {...(pathname.endsWith('/jobs-hiring/job-search') && {
                          style: {
                            fontWeight: 500
                          }
                        })}
                        to={'/' + langKey + '/jobs-hiring/job-search'}
                        title='On-site  Jobs'
                        onClick={handleClick}
                      >
                        <p
                          className={styles.menuList}
                          {...(pathname.endsWith('/jobs-hiring/job-search') && {
                            'data-gtag-event': 'rl_active_find_jobs_onsite'
                          })}
                        >
                          {onSiteJobs}
                        </p>
                      </Link>
                    </Menu.Item>
                    {isAuthenticated && (
                      <Menu.Item key='3'>
                        <Link
                          {...(pathname.endsWith('/my-jobs') && {
                            style: {
                              fontWeight: 500
                            }
                          })}
                          className={styles.defaultLink}
                          to={'/' + langKey + '/my-jobs?page=1'}
                          title='Recommended Jobs'
                          onClick={handleClick}
                        >
                          <p
                            className={styles.menuList}
                            {...(pathname.endsWith('/my-jobs') && {
                              'data-gtag-event': 'rl_active_find_jobs_recommend'
                            })}
                          >
                            {recommendedJobs}
                          </p>
                        </Link>
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                </MenuStyled>
                {/* </li> */}
              </div>
              <Link
                className={styles.defaultLink}
                to='/companies'
                title='Companies'
                {...(pathname.endsWith('/companies') && {
                  style: {
                    fontWeight: 500
                  }
                })}
              >
                <p
                  className={styles.menuList}
                  onClick={handleClick}
                  {...(pathname.endsWith('/companies') && {
                    'data-gtag-event': 'rl_active_company'
                  })}
                >
                  <span>{companies}</span>
                </p>
              </Link>
              <Link
                className={styles.defaultLink}
                to='/remote-jobs'
                title='remote-jobs'
                {...(pathname.endsWith('/remote-jobs') && {
                  style: {
                    fontWeight: 500
                  }
                })}
              >
                <p className={styles.menuList} onClick={handleClick}>
                  {remoteJobs}
                </p>
              </Link>
              {/* {isAuthenticated && (
                <>
                  <Link
                    className={styles.defaultLink}
                    to='/manage-profile'
                    // to={
                    //   userCookie?.is_profile_completed
                    //     ? '/manage-profile'
                    //     : '/jobseeker-complete-profile'
                    // }
                    // to={userCookie?.is_profile_completed ? authPathToOldProject(null, '/dashboard/profile/jobseeker') : '/jobseeker-complete-profile/1'}
                    // aTag
                    title='Manage Resume'
                  >
                    <p className={styles.menuList} onClick={handleClick}>
                      <Text
                        textStyle={textStyle}
                        // className={
                        //   styles.activeLink + ' ' + (showUnCompletedDot ? styles.unCompleted : '')
                        // }
                        className={showUnCompletedDot ? styles.unCompleted : ''}
                      >
                        {manageResume}
                      </Text>
                    </p>
                  </Link>
                  <Link className={styles.defaultLink} to='/my-jobs?page=1' title='My Jobs'>
                    <p className={styles.menuList} onClick={handleClick}>
                      <Text textStyle={textStyle}>{recommendedJobs}</Text>
                    </p>
                  </Link>
                  <Link
                    className={styles.defaultLink}
                    to='/settings'
                    title='Account Settings'
                  >
                    <p className={styles.menuList} onClick={handleClick}>
                      <Text textStyle={textStyle}>{accountSettings}</Text>
                    </p>
                  </Link>
                   <Link className={styles.defaultLink} title='Jobs' to='/chat/list'>
                  <li className={styles.menuList} onClick={handleClick}>
                    <Text textStyle={textStyle}>{Chat}</Text>
                  </li>
                </Link> 
            </>
              )} */}
              {/* <Link
              className={`${styles.defaultLink}`}
              to='https://academy.bossjob.ph/courses/search-courses'
              aTag
              title='Courses'
            >
              <li className={styles.menuList} onClick={handleClick}>
                <Text textStyle={textStyle}>{courses}</Text>
              </li>
            </Link> */}

              <Link
                className={styles.defaultLink}
                to={`https://blog.bossjob.${countryKey === 'jp' ? 'jp' : 'ph'}`}
                external
                title='Career Guide'
              >
                <p
                  className={styles.menuList}
                  onClick={handleClick}
                  {...(pathname.endsWith('/remote-jobs') && {
                    'data-gtag-event': 'rl_active_career_guide'
                  })}
                >
                  {careerGuide}
                </p>
              </Link>
              {/* <Link
                className={`${styles.defaultLink}`}
                to={linkToHunt('')}
                aTag
                title='Employers'
              >
                <p className={styles.menuList} onClick={handleClick}
                  {
                  ...(
                    pathname.endsWith('/remote-jobs') && {
                      'data-gtag-event': 'rl_active_employer'
                    })
                  }
                >
                  <Text textStyle={textStyle}>{hiring}</Text>
                </p>
              </Link> */}

              {/* {isAuthenticated && (
                <>
                  <Link
                    className={styles.defaultLink}
                    to='https://blog.bossjob.ph/'
                    aTag
                    title='Career Guide'
                  >
                    <p className={styles.menuList} onClick={handleClick}
                      {
                      ...(
                        pathname.endsWith('/remote-jobs') && {
                          'data-gtag-event': 'rl_active_career_guide'
                        })
                      }
                    >
                      <Text textStyle={textStyle}>{careerGuide}</Text>
                    </p>
                  </Link>





                </>
              )} */}

              <ChangeLangAndRegion
                zIndex={1002}
                show={show}
                onAction={({ show }) => {
                  setShow(show)
                }}
              />
            </React.Fragment>
          </div>
        </div>
        <div
          style={{
            paddingBottom: 24
          }}
        >
          <Link className={styles.defaultLink} to='/talents' target='_blank' title='Bossjob App'>
            <div
              className={styles.downloadApp}
              {...(pathname.endsWith('/remote-jobs') && {
                'data-gtag-event': 'rl_active_app'
              })}
            >
              <span style={{ color: '#fff' }}>{downloadApp}</span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='14'
                height='14'
                viewBox='0 0 14 14'
                fill='none'
              >
                <path
                  d='M7.9958 1.92085C7.768 1.69305 7.39865 1.69305 7.17085 1.92085C6.94304 2.14866 6.94304 2.51801 7.17085 2.74581L10.8417 6.41667L1.74999 6.41667C1.42782 6.41667 1.16666 6.67783 1.16666 7C1.16666 7.32217 1.42782 7.58333 1.74999 7.58333L10.8417 7.58333L7.17085 11.2542C6.94304 11.482 6.94304 11.8513 7.17085 12.0791C7.39865 12.307 7.768 12.307 7.9958 12.0791L12.6625 7.41248C12.8903 7.18467 12.8903 6.81533 12.6625 6.58752L7.9958 1.92085Z'
                  fill='white'
                />
              </svg>
            </div>
          </Link>
          <div
            style={{
              width: 208,
              height: 1,
              margin: '8px auto',
              background: '#f6f6f6'
            }}
          ></div>
          <div style={{ padding: '0 24px', fontSize: 14 }}>
            <Link to={linkToHunt('')} aTag>
              <div
                {...(pathname.endsWith('/remote-jobs') && {
                  'data-gtag-event': 'rl_active_employer'
                })}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: 48
                }}
              >
                <span style={{ color: '#000' }}>{hiring}</span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                >
                  <path
                    d='M5.225 5.40809C5.225 4.02642 6.34515 2.90628 7.72702 2.90628C9.10888 2.90628 10.229 4.02642 10.229 5.40809C10.229 6.78976 9.10888 7.90989 7.72702 7.90989C6.34515 7.90989 5.225 6.78976 5.225 5.40809ZM7.72702 1.57574C5.6104 1.57574 3.89446 3.2915 3.89446 5.40809C3.89446 6.64683 4.48221 7.74829 5.39405 8.44885C3.39013 9.37649 1.99997 11.4051 1.99997 13.7584V14.4237H3.33051V13.7584C3.33051 11.2636 5.35305 9.24117 7.84807 9.24117C7.88028 9.24117 7.91196 9.23888 7.94295 9.23445C9.95912 9.12248 11.5596 7.45221 11.5596 5.40809C11.5596 3.2915 9.84364 1.57574 7.72702 1.57574ZM11.5361 9.37357C11.7313 9.56883 11.7313 9.88542 11.536 10.0807L11.2379 10.3788H13.486C13.7621 10.3788 13.986 10.6026 13.986 10.8788C13.986 11.1549 13.7621 11.3788 13.486 11.3788H10.0308C9.82853 11.3788 9.64621 11.2569 9.56883 11.0701C9.49144 10.8833 9.53422 10.6682 9.67723 10.5252L10.829 9.37354C11.0242 9.17829 11.3408 9.1783 11.5361 9.37357ZM11.9805 13.4034C11.7853 13.5987 11.7853 13.9153 11.9805 14.1105C12.1758 14.3058 12.4924 14.3058 12.6876 14.1105L13.8394 12.9589C13.9824 12.8159 14.0251 12.6008 13.9478 12.414C13.8704 12.2271 13.6881 12.1053 13.4858 12.1053H10.0306C9.75446 12.1053 9.5306 12.3292 9.5306 12.6053C9.5306 12.8815 9.75446 13.1053 10.0306 13.1053H12.2787L11.9805 13.4034Z'
                    fill='#121212'
                  />
                </svg>
              </div>
            </Link>
          </div>
          <div
            onClick={() => {
              // postNotification('SHOW_SIDE_GUIDE_MODAL')
              // handleClick()
              setShow(true)
            }}
            style={{ padding: '12px 24px 0', fontSize: 14 }}
            {...(pathname.endsWith('/remote-jobs') && {
              'data-gtag-event': 'rl_active_country_language'
            })}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 48
              }}
            >
              <div>
                <span style={{ color: '#000' }}>{lang?.comm?.countryAndLanguage}</span>
                <span
                  style={{
                    display: 'flex',
                    padding: '6px 0',
                    alignItems: 'center',
                    fontSize: 14,
                    color: '#7D7D7D'
                  }}
                >
                  <span
                    style={{
                      width: 24,
                      height: 16,
                      // borderRadius: '50%',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundImage:
                        getCountryKey() !== 'com'
                          ? `url(${S3BucketJobseeker}/flags/${getCountryKey()?.toUpperCase()}.png)`
                          : `url(${S3BucketJobseeker}/flags/flag_inter.png)`,
                      border: '1px solid #f6f6f6',
                      marginRight: 8
                    }}
                  />{' '}
                  | <span style={{ marginLeft: 8 }}>{languageSlug?.toUpperCase()}</span>
                  {/* <span style={{ color: '#136FD3' }}>{change}</span> */}
                </span>
              </div>

              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
              >
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M5.21059 2.84024C5.44201 2.60882 5.81722 2.60882 6.04864 2.84024L10.7894 7.58098C11.0208 7.8124 11.0208 8.18761 10.7894 8.41903L6.04864 13.1598C5.81721 13.3912 5.44201 13.3912 5.21058 13.1598C4.97916 12.9284 4.97916 12.5531 5.21058 12.3217L9.5323 8.00001L5.21059 3.67829C4.97916 3.44687 4.97916 3.07166 5.21059 2.84024Z'
                  fill='#121212'
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const MenuStyled = styled(Menu)`
  &.ant-menu&.ant-menu-root&.ant-menu-inline&.ant-menu-light {
    border-inline-end: none;
    margin: 0;
  }

  & .ant-menu-submenu-title {
    margin: 0;
    width: 100%;
    color: #000;
    &:hover {
      background-color: #fff !important;
    }
  }
  & .ant-menu-submenu-open {
    & .ant-menu-submenu-title {
      color: #2378e5;
      font-weight: 500;
    }
  }

  .ant-menu-submenu {
    padding: 0;

    .ant-menu-submenu-title {
      height: 50px;

      /* span {
                      color: #353535;
      }

                    .ant-menu-submenu-arrow {
                      color: #353535;
      } */
    }

    & .ant-menu {
      background: #f8f8f8 !important;
      & .ant-menu-item {
        display: flex;
        padding: 0;
        margin: 0;
        padding: 0 0 0 34px !important;
        justify-content: center;
        align-items: center;

        align-self: stretch;

        height: 48px;
        /* margin-bottom: 8px; */
        line-height: 43px;

        & .ant-menu-title-content {
          color: #000 !important;
          a {
            padding: 0;
            color: #121212;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
          }
        }
      }

      & .ant-menu-item:hover {
        background: #f6f6f6;
      }
    }
  }
`

export default HamburgerMenu
