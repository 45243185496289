/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
'use client'
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import { flushSync } from 'react-dom'
import { flatMap } from 'lodash-es'
import JobSearchBar from '../../../../../../components/commons/location/search'
import styles from '../../index.module.scss'
import Single from 'app/components/mobile/select/single'
import Multiple from 'app/components/mobile/select/NewMultiple/multiple'
import MultyGroup from 'app/components/mobile/select/groupedMulty'
import { encode, zipLocation } from '../../../interpreters/encoder'
import { useSuggest } from './hooks'
import theme from 'app/components/mobile/theme'
import { ThemeProvider } from '@mui/material/styles'
import { toPairs } from 'ramda'
import { useFirstRender } from 'helpers/useFirstRender'
import { filter } from 'ramda'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { cloneDeep } from 'lodash-es'

import JobFunction from 'app/components/commons/NewJobFunction'
import LocationMultiSelector from 'app/components/commons/NewLocationMulty'
import SalarySelect from 'app/components/commons/NewSalarySelect'

import { HistoryIcons } from 'images'
import { convertSpecialCharacters } from 'helpers/utilities'
const SearchArea = (props: any) => {
  const { searchValues, dictionary, initialConfig: config = {} } = props
  const {
    sort_filter = [],
    location_lists = [],
    main_function_filter = [],
    salary_type_lists = [],
    job_types, industry_lists = [],

    input_text,
    search_text,
    location_text,
    main_function_text,
    industry_text,
    salary_text,
    more_filter_text,

    filter_reset_text,
    filter_apply_text
  } = config

  const translation = {
    filter_reset_text,
    filter_apply_text
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { search, myJobs, home } = dictionary
  const sortOptions = sort_filter.map(item => {
    return { label: item.value, value: item.id?.toString() }
  })

  const page = searchValues.page ?? '1'
  const router = useRouter()
  const locations = flatMap(config.location_lists, (item) => item.locations)
  const [location, setLocation] = useState(
    locations.filter((location) => searchValues?.location?.includes(location.seo_value))
  )

  const [industry, setIndustry] = useState(searchValues.industry ?? [])
  const industryList =
    industry_lists?.map?.((item) => ({ ...item, sub_filter: (item.sub_filter ?? []).map(child => ({ label: child.value, value: child.seo_value })) }))

  const [queryFields, setQueryFields] = useState(searchValues.queryFields)
  const [jobFunctionValue, jobFunctionChange] = useState({
    functionTitles: searchValues?.functionTitles ?? [],
    jobFunctions: searchValues?.jobFunctions ?? [],
    mainFunctions: searchValues?.mainFunctions ?? []
  })
  const moreDataOptions = useMemo(() => {
    const workExperience =
      config.xp_lvls?.map?.((item) => ({ value: item?.['seo_value'], label: item.value })) ?? []
    const qualification =
      config.degrees?.map?.((item) => ({ value: item?.['seo_value'], label: item.value })) ?? []
    const salary =
      config?.salary_range_filters?.map?.((item) => ({
        value: item?.['seo_value'],
        label: item.value
      })) ?? []
    const jobType =
      job_types?.map?.((item) => ({ value: item?.['seo_value'], label: item.value })) ?? []
    const companySizes =
      config.company_sizes?.map?.((item) => ({ value: item?.['seo_value'], label: item.value })) ??
      []
    const financingStages =
      config.company_financing_stage_lists?.map?.((item) => ({ value: item?.['seo_value'], label: item.value })) ?? []
    return {
      workExperience,
      qualification,
      // salary,
      jobType,
      companySizes,
      financingStages
    }
  }, [config])
  const labels = [
    search?.searchModal?.exp,
    search?.searchModal?.edu,
    // search?.salary,
    search?.type,
    search?.searchModal?.companySize,
    search?.searchModal?.finance
  ]
  const [sort, setSort] = useState(searchValues?.sort?.[0] ?? '2')

  const [moreData, setMoreData] = useState(
    filter((a: any) => a)({
      workExperience: searchValues?.workExperience ?? null,
      qualification: searchValues?.qualification ?? null,
      // salary: searchValues?.salary ?? null,
      jobTypes: searchValues?.jobType ?? null,
      verifiedCompany: searchValues?.verifiedCompany ?? null,
      companySizes: searchValues?.companySizes ?? null,
      financingStages: searchValues?.financingStages ?? null
    })
  )
  const [searchValue, setSearchValue] = useState(searchValues.query)
  const [suggestionList, handleSuggestionSearch, addSearchHistory] = useSuggest() as any[]
  const [salaries, setSalaries] = useState({
    salary: searchValues?.salary ?? [],
    salaryType: searchValues?.salaryType ?? []
  })
  const filterParams = useMemo(() => {
    const keyWords = searchValue?.trim?.().replace(/\s+/g, ' ')
    return filter((a: any) => a)({
      query: convertSpecialCharacters(keyWords),
      queryFields,
      industry: industry.length ? industry : null,
      location: location?.map((a) => a['seo_value']),
      sort: sort,
      page: page,
      ...salaries,
      ...jobFunctionValue,
      ...moreData
    })
  }, [searchValue, industry, moreData, location, sort, jobFunctionValue, queryFields, salaries])
  const result = useMemo(() => {
    return encode(filterParams)
  }, [filterParams])
  const firstRender = useFirstRender()
  const reload = useCallback(async () => {
    if (firstRender) {
      return
    }
    const zippedParams = await zipLocation(result.params)
    const url = new URLSearchParams(toPairs(zippedParams)).toString()
    router.push('/jobs-hiring/' + result.searchQuery + '?' + url)
  }, [result])
  const reloadRef = useRef(reload)
  useEffect(() => {
    reloadRef.current = reload
  }, [reload])
  useEffect(() => {
    reload()
  }, [location, industry, moreData, sort, jobFunctionValue, queryFields, salaries])
  const styleleSelect = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }

  const newTheme = cloneDeep(theme)
  newTheme.components.MuiPaper.styleOverrides.root['height'] = 'calc(100% - 64px)'

  return (
    <div>
      <ThemeProvider theme={newTheme}>
        <div className={styles.searchFormMoblie}>
          <div className={styles.searchArea}>
            <JobSearchBar
              id='search'
              // label={search.title}
              variant='outlined'
              placeholder={input_text}
              size='small'
              className={styles.search}
              value={searchValue}
              maxLength={255}
              renderOption={(props, option) => {
                const { type, is_history: isHistory, value, logo_url: logoUrl } = option || {}
                return type === 'company' ? (
                  <li {...props} style={styleleSelect}>
                    <Image src={logoUrl} alt={value} width='22' height='22' />
                    <span style={{ paddingLeft: '10px' }}>{value}</span>
                  </li>
                ) : isHistory ? (
                  <li {...props} style={{ ...styleleSelect, color: '#136fd3' }}>
                    <AccessTimeIcon />
                    <span style={{ paddingLeft: '10px' }}>{value}</span>
                  </li>
                ) : (
                  <li {...props} style={styleleSelect}>
                    <Image src={HistoryIcons} alt='history icons' width='17' height='17' />
                    <span style={{ paddingLeft: '10px' }}>{value || option}</span>
                  </li>
                )
              }}
              searchFn={handleSuggestionSearch as any}
              updateSearchValue={setSearchValue}
              enterKeyHint='search'
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault()
                  setQueryFields('')
                  flushSync(() => {
                    setSearchValue((e.target as HTMLInputElement).value)
                  })
                  addSearchHistory?.((e.target as HTMLInputElement).value)
                    ; ((e.target ?? {}) as any)?.blur?.()
                  reloadRef?.current?.()
                }
              }}
              options={suggestionList}
              onSelect={(value: any) => {
                const newValue = value?.value || value || ''
                setQueryFields(value?.type || '')
                flushSync(() => {
                  setSearchValue(newValue)
                })
                addSearchHistory(newValue)
                reloadRef.current()
              }}
            />
            <button
              className={styles.searchButton}
              onClick={() => {
                flushSync(() => {
                  setSearchValue(searchValue)
                })
                addSearchHistory(searchValue)
                reloadRef.current()
                setQueryFields('')
              }}
            >
              {search_text}
            </button>
          </div>
          <div className={styles.filters}>
            <LocationMultiSelector
              className={styles.location}
              locationList={location_lists}
              value={location}
              isMobile={true}
              lang={search}
              label={location_text}
              translation={translation}
              onChange={setLocation}
              searchValueLocations={searchValues?.location || {}}
              sx={{
                '> .MuiFormControl-root': {
                  borderRadius: '8px',
                  height: '60px',
                  marginTop: '4px',
                  overflow: 'hidden',
                  '> .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    height: '60px',
                    overflow: 'hidden',
                    marginTop: '4px'
                  }
                }
              }}
            />
            <Single
              options={sortOptions}
              value={sort}
              onSelect={setSort}
              className={styles.filterItems}
              label={myJobs.sortBy}
              menuClassName={'popMOdalContainer'}
            />
            <JobFunction
              id='jobFunction'
              label={main_function_text}
              value={jobFunctionValue}
              className={[styles.filterItems, styles.jobFunctions]}
              onChange={jobFunctionChange}
              lang={search}
              jobFunctions={main_function_filter}
            />
            <Multiple
              label={industry_text}
              value={industry}
              options={industryList}
              className={styles.filterItems}
              onSelect={setIndustry}
              lang={search}
              menuClassName={'popMOdalContainer'}
            />
            <SalarySelect
              id='SalarySelect'
              salaryTypeLists={salary_type_lists}
              label={salary_text}
              value={salaries}
              lang={search}
              className={styles.filterItems}
              onChange={setSalaries}
            />
            <MultyGroup
              label={more_filter_text}
              value={moreData}
              labels={labels}
              options={moreDataOptions}
              className={styles.filterItems}
              onSelect={setMoreData}
              lang={search}
              menuClassName={'popMOdalContainer'}
            />
          </div>
        </div>
      </ThemeProvider>
    </div>
  )
}
export default SearchArea
